@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "EZ-Allianz";
  src: url("./fonts/ESAllianzTRIAL-Regular.eot");
  src: url("./fonts/ESAllianzTRIAL-Regular.eot#iefix") format("embedded-opentype"),
    url("./fonts/ESAllianzTRIAL-Regular.woff2") format("woff2"),
    url("./fonts/ESAllianzTRIAL-Regular.woff") format("woff"),
    url("./fonts/ESAllianzTRIAL-Regular.ttf") format("truetype");
}

.pickup-report-chart ::-webkit-scrollbar {
  /* height: 3px; */
}

.pickup-report-chart ::-webkit-scrollbar-thumb {
  background: #888;
}

.competitor_prices .recharts-wrapper,
.website-traffic-time .recharts-wrapper,
.website-traffic-device .recharts-wrapper {
  position: unset !important;
}

/** override tailwind css */
.custom-message {
  /* font-size: 0.75rem; */
}

.custom-message h1,
.custom-message h2,
.custom-message h3,
.custom-message h4,
.custom-message h5,
.custom-message h6 {
  /* font-size: revert !important; */
  font-weight: revert !important;
}

.custom-message p,
.custom-message pre,
.custom-message blockquote,
.custom-message dl,
.custom-message dd,
.custom-message hr,
.custom-message figure,
.custom-message dl,
.custom-message dd,
.custom-message h1,
.custom-message h2,
.custom-message h3,
.custom-message h4,
.custom-message h5,
.custom-message h6 {
  margin: revert !important;
}

body {
  margin: 0;
  font-family: "EZ-Allianz", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "EZ-Allianz", sans-serif !important;
}

@media only screen and (max-width: 1440px) {
  .rdp-root {
    --rdp-day-height: 2.75rem !important;
    /* The height of the day cells. */
    --rdp-day-width: 2rem !important;
    /* The width of the day cells. */
  }

  body.sidebar-open .rdp-root {
    --rdp-accent-color: #303E38;
    /* The accent color used for selected days and UI elements. */
    --rdp-accent-background-color: #f0f0ff;
    /* The accent background color used for selected days and UI elements. */
    --rdp-font-family: "EZ-Allianz" !important;
    /* The font family used by the calendar. Note that `inherit`does not work here. */

    --rdp-day-font: inherit;
    /* The font used for the day cells. */
    --rdp-day-height: 2.75rem !important;
    /* The height of the day cells. */
    --rdp-day-width: 1.75rem !important;
    /* The width of the day cells. */

    --rdp-day_button-border-radius: 100%;
    /* The border radius of the day cells. */
    --rdp-day_button-border: 2px solid transparent;
    /* The border of the day cells. */
    --rdp-day_button-height: var(--rdp-day-height) !important;
    /* The height of the day cells. */
    --rdp-day_button-width: var(--rdp-day-width) !important;
    /* The width of the day cells. */

    --rdp-selected-border: 2px solid var(--rdp-accent-color);
    /* The border of the selected days. */
    --rdp-selected-font: bold large var(--rdp-font-family);
    /* The font of the selected days. */
    --rdp-disabled-opacity: 0.5;
    /* The opacity of the disabled days. */
    --rdp-outside-opacity: 0.75;
    /* The opacity of the days outside the current month. */
    --rdp-today-color: var(--rdp-accent-color);
    /* The color of the today's date. */

    --rdp-dropdown-gap: 0.5rem;
    /* The gap between the dropdowns used in the month captons. */

    --rdp-month_caption-font: bold larger var(--rdp-font-family);
    /* The font of the month caption. */
    --rdp-months-gap: 2rem;
    /* The gap between the months in the multi-month view. */

    --rdp-nav_button-disabled-opacity: 0.5;
    /* The opacity of the disabled navigation buttons. */
    --rdp-nav_button-height: 2.25rem;
    /* The height of the navigation buttons. */
    --rdp-nav_button-width: 2.25rem;
    /* The width of the navigation buttons. */
    --rdp-nav-height: 2.75rem;
    /* The height of the navigation bar. */

    --rdp-range_middle-background-color: var(--rdp-accent-background-color);
    /* The color of the background for days in the middle of a range. */
    --rdp-range_middle-font: normal medium var(--rdp-font-family);
    /* The font for days in the middle of a range. */
    --rdp-range_middle-foreground-color: white;
    /* The font for days in the middle of a range. */
    --rdp-range_middle-color: inherit;
    /* The color of the range text. */

    --rdp-range_start-color: white;
    /* The color of the range text. */
    --rdp-range_start-background: linear-gradient(var(--rdp-gradient-direction),
        transparent 50%,
        var(--rdp-range_middle-background-color) 50%);
    /* Used for the background of the start of the selected range. */
    --rdp-range_start-date-background-color: var(--rdp-accent-color);
    /* The background color of the date when at the start of the selected range. */

    --rdp-range_end-background: linear-gradient(var(--rdp-gradient-direction),
        var(--rdp-range_middle-background-color) 50%,
        transparent 50%);
    /* Used for the background of the end of the selected range. */
    --rdp-range_end-color: white;
    /* The color of the range text. */
    --rdp-range_end-date-background-color: var(--rdp-accent-color);
    /* The background color of the date when at the end of the selected range. */

    --rdp-week_number-border-radius: 100%;
    /* The border radius of the week number. */
    --rdp-week_number-border: 2px solid transparent;
    /* The border of the week number. */
    --rdp-week_number-font: 400 small var(--rdp-font-family);
    /* The font of the week number cells. */
    --rdp-week_number-height: var(--rdp-day-height);
    /* The height of the week number cells. */
    --rdp-week_number-opacity: 0.75;
    /* The opacity of the week number. */
    --rdp-week_number-width: var(--rdp-day-width);
    /* The width of the week number cells. */
    --rdp-weeknumber-text-align: center;
    /* The text alignment of the weekday cells. */

    --rdp-weekday-font: 500 smaller var(--rdp-font-family);
    /* The font of the weekday. */
    --rdp-weekday-opacity: 0.75;
    /* The opacity of the weekday. */
    --rdp-weekday-padding: 0.5rem 0rem;
    /* The padding of the weekday. */
    --rdp-weekday-text-align: center;
    /* The text alignment of the weekday cells. */

    --rdp-gradient-direction: 90deg;
  }
}

::placeholder {
  color: #8b8c91;
  opacity: 1;
}

::-ms-input-placeholder {
  color: #8b8c91;
}