.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bricolage-grotesque {
  font-family: "Bricolage Grotesque", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.auth-layout {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.input-sizer {
  display: inline-grid;
  vertical-align: top;
  align-items: center;
  position: relative;
}

.input-sizer::after,
.input-sizer input,
.input-sizer textarea {
  width: auto;
  min-width: 1em;
  grid-area: 1 / 2;
  font: inherit;
  padding: 0.25em;
  margin: 0;
  resize: none;
  background: none;
  appearance: none;
  border: none;
}
.input-sizer::after {
  content: attr(data-value) " ";
  visibility: hidden;
  white-space: pre-wrap;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.recharts-rectangle.recharts-tooltip-cursor {
  fill: url(#gradient);
}
.recharts-cartesian-grid-horizontal > line {
  stroke: #f9fafc;
}

/**
 * react-day-picker styles
 */
.rdp-nav,
.rdp-month_caption {
  display: none !important;
}

/**
  * react-day-picker styles
  */

.rbc-calendar {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid var(--Neutral-400, #E5E7EB);
  padding-top: 1rem;
}

.rbc-month-view {
  border-width: 0px !important;
  border-top-width: 1px !important;
}

.rdp-weekday {
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 11px !important;
}

.rbc-day-bg + .rbc-day-bg {
  border-left-color: #E5E7EB !important;
}

.rbc-off-range-bg {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.2) !important;
}

.rbc-current > button {
  background-color: black !important;
  border-radius: 4px;
  width: 24px !important;
  height: 24px !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 11px !important;
}

.rbc-date-cell > button {
  font-weight: 500 !important;
  font-size: 11px !important;
  display: none;
}

.rbc-month-header .rbc-header {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  color: #000;
  font-size: 12px;
  font-weight: 500;
}

.rdp-day:has(> div > button > button.selected) {
  background-color: black !important;
  color: #fff !important;
  border-radius: 4px !important;
}

/* HTML: <div class="loader"></div> */
.chatbot-loader {
  width: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
  0% {
    box-shadow: 14px 0 #0B252A, -14px 0 #F2F7F8;
    background: #0B252A;
  }
  33% {
    box-shadow: 14px 0 #0B252A, -14px 0 #E6EDEA;
    background: #D8E6E0;
  }
  66% {
    box-shadow: 14px 0 #AEC8BD, -14px 0 #0B252A;
    background: #C4DAD0;
  }
  100% {
    box-shadow: 14px 0 #AEC8BD, -14px 0 #0B252A;
    background: #2F3D37;
  }
}

.graph-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

/* Hide scrollbar for all elements */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}